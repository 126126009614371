import React from 'react'
import img from '../Assets/images/hero.jpg'
import { Link } from 'react-router-dom'

function Hero() {
    return (
        <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
            <div className="container mx-auto px-6 flex relative py-16">
                <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
                    <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                    <h1 className="font-bebas-neue uppercase text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-800">
                        Buy Your
                        <span className="text-5xl sm:text-7xl">Next Car</span>
                    </h1>
                    <p className="text-sm sm:text-base text-gray-700 dark:text-white">
                        "Welcome to AICORPORATION - your trusted source for quality
                        pre-owned and new cars. Browse our curated selection of top automotive
                        brands and find your perfect ride today!
                    </p>
                    <div className="flex mt-8">
                        <Link
                            to={"/cars"}
                            className="uppercase py-2 px-4 rounded-lg bg-neutral border-2 border-transparent text-white text-md mr-4 hover:bg-blue-400"
                        >
                            Browse cars
                        </Link>
                        <Link
                           to={"/about"}
                            className="uppercase py-2 px-4 rounded-lg bg-transparent border-2 border-neutral-500 bg-neutral dark:text-white hover:bg-blue-400 hover:text-white text-md"
                        >
                            About Us
                        </Link>
                    </div>
                </div>
                <div className="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
                <img src={img} className="max-w-xl md:max-w-2xl m-auto" />


                </div>
            </div>
        </div>

    )
}

export default Hero