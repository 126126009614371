import React from 'react'
import Hero from '../Components/Hero'
import Recent from '../Components/Recent'

function Home() {
    return (
        <>
            <Hero />
            <div className='h-11 bg-white'>

            </div>
            <Recent />
           
        </>
    )
}

export default Home