import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

function Cars() {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCar = async () => {
            try {
                const response = await fetch(`http://data.aicorporationjp.com/getcarbyid.php`);

                if (!response.ok) {
                    throw new Error('Failed to fetch car details');
                }

                const data = await response.json();
                setProducts(data);
                setLoading(false);
                console.log(data);
            } catch (error) {
                
                setLoading(false);
            }
        };

        fetchCar();
        window.scrollTo(0, 0);
    }, []);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 9;
    const totalPages = Math.ceil(products.length / productsPerPage);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const nextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="bg-neutral py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-white mb-8 text-center">
                    Cars Ready to Go
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {currentProducts.map(product => (
                        <div key={product.id} className="bg-white rounded-lg shadow-lg p-8">
                            <div className="relative overflow-hidden">
                                <img
                                    className="object-cover w-full h-full"
                                    src={product.image1}
                                    alt="Product"
                                />
                            </div>
                            <h3 className="text-xl font-bold text-gray-900 mt-4">{product.title}</h3>
                            <p className="text-gray-500 text-sm mt-2">
                                {product.condition}
                            </p>
                            <div className="flex items-center justify-between mt-4">
                                <span className="text-gray-900 font-bold text-lg">
                                    ${product.price}</span>
                                <Link to={`/details/${product.id}`}>
                                    <button className="bg-gray-900 text-white py-2 px-4 rounded-full font-bold hover:bg-gray-800">
                                        More Details
                                    </button>
                                </Link>

                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center mt-8 gap-11">
                    <button
                        className="uppercase py-2 px-4 rounded-lg bg-gray-600 border-2 border-transparent text-white text-md mr-4 hover:bg-blue-400"
                        onClick={prevPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <button
                        className="uppercase py-2 px-4 rounded-lg bg-gray-600 border-2 border-transparent text-white text-md mr-4 hover:bg-blue-400"
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Cars