import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Recent() {
    const [car, setCar] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState([]);
    const productsPerPage = 6;

    useEffect(() => {
        const fetchCar = async () => {
            try {
                const response = await fetch(`http://data.aicorporationjp.com/getcarbyid.php`);

                if (!response.ok) {
                    throw new Error('Failed to fetch car details');
                }

                const data = await response.json();
                const shuffledCars = shuffleArray(data);
                const selectedCars = shuffledCars.slice(0, productsPerPage);
                
                setCar(selectedCars);
                setCurrentPage(selectedCars);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchCar();
    }, []);

    // Function to shuffle array
    const shuffleArray = (array) => {
        let currentIndex = array.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    };

    return (
        <div className="bg-neutral py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-white mb-8 text-center">
                    Recently Uploaded Cars
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {currentPage.map(product => (
                        <div key={product.id} className="bg-white rounded-lg shadow-lg p-8">
                            <div className="relative overflow-hidden">
                                <img
                                    className="object-cover w-full h-full"
                                    src={product.image1}
                                    alt="Product"
                                />
                                <div className="absolute inset-0 bg-black opacity-40" />
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <Link to={`/details/${product.id}`}>
                                        <button className="bg-white text-gray-900 py-2 px-6 rounded-full font-bold hover:bg-gray-300">
                                            View Car Details
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <h3 className="text-xl font-bold text-gray-900 mt-4">{product.title}</h3>
                            <p className="text-gray-500 text-sm mt-2">
                                {product.condition}
                            </p>
                            <div className="flex items-center justify-between mt-4">
                                <span className="text-gray-900 font-bold text-lg">${product.price}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Recent;
