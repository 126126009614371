import React from 'react'
import card from '../Assets/images/business_card.jpeg'

function About() {
    return (
        <section className="">
            <div className="py-12 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <h2 className="font-heading mb-4 bg-orange-100 text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
                            Why choose us?
                        </h2>
                        <p className="font-heading mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
                            AI CORPORATION
                        </p>
                        <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                            A premier destination for car enthusiasts and aficionados. Located in  株式会社　ＡＩ　ＣＯＲＰＯＲＡＴＩＯＮ

                            〒936-0053 富山県滑川市上小泉１９６６-６, we are dedicated to providing an unparalleled experience for those who share a passion for automobiles.
                        </p>
                    </div>
                    <div className="mt-10">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                        <img src="https://www.svgrepo.com/show/503163/api-settings.svg" alt="API Settings" />
                                    </div>
                                    <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                                        Extensive Car Listings
                                    </p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    Our platform offers an extensive range of car listings with detailed car specifications, providing users with comprehensive information.
                                </dd>
                            </div>
                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                        <img src="https://www.svgrepo.com/show/503138/webpack.svg" alt="Webpack" />
                                    </div>
                                    <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                                        User-Friendly Search & Filter
                                    </p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    Our platform provides an intuitive search and filter system, enabling users to easily find cars based on specific criteria.
                                </dd>
                            </div>
                            <div className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                                        <img src="https://www.svgrepo.com/show/511771/dashboard-671.svg" alt="Dashboard" />
                                    </div>
                                    <p className="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                                        Direct Contact with Sellers
                                    </p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">
                                    Our platform allows direct communication between buyers and sellers, facilitating a transparent and efficient selling process.
                                </dd>
                            </div>
                        </dl>
                    </div>

                </div>
            </div>
            <h2 className="font-heading mb-4 text-center bg-orange-100 text-orange-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
                business card
            </h2>
            <div className='mt-11 mb-11  '>
                <img
                    className="w-1/2 h-auto mx-auto shadow-2xl bg-white"
                    src={card}
                    alt="Card"
                />

            </div>

        </section>

    )
}

export default About