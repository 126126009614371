import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

function Details() {
    const [car, setCar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [contactstate, setContactstate] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchCar = async () => {
            try {
                const response = await fetch(`http://data.aicorporationjp.com/getcarbyid.php?id=${id}`);

                if (!response.ok) {
                    throw new Error('Failed to fetch car details');
                }

                const data = await response.json();
                setCar(data);
                setLoading(false);
                console.log(data);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchCar();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <div className="font-[sans-serif] bg-neutral-200">
            <div className="p-6 lg:max-w-7xl max-w-4xl mx-auto">
                <div className="grid items-start grid-cols-1 lg:grid-cols-5 gap-12 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-6">
                    <div className="lg:col-span-3 w-full lg:sticky top-0 text-center">
                        <div className="px-4 py-10 rounded-xl shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative">
                            <div className="carousel">
                                <div id="slide1" className="carousel-item relative w-full">
                                    <img src={car.image1} />
                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                                        <a href="#slide4" className="btn btn-circle">❮</a>
                                        <a href="#slide2" className="btn btn-circle">❯</a>
                                    </div>
                                </div>
                                <div id="slide2" className="carousel-item relative w-full">
                                    <img src={car.image2} />
                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                                        <a href="#slide1" className="btn btn-circle">❮</a>
                                        <a href="#slide3" className="btn btn-circle">❯</a>
                                    </div>
                                </div>
                                <div id="slide3" className="carousel-item relative w-full">
                                    <img src={car.image3} />
                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                                        <a href="#slide2" className="btn btn-circle">❮</a>
                                        <a href="#slide4" className="btn btn-circle">❯</a>
                                    </div>
                                </div>
                                <div id="slide4" className="carousel-item relative w-full">
                                    <img src={car.image4} />
                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                                        <a href="#slide3" className="btn btn-circle">❮</a>
                                        <a href="#slide1" className="btn btn-circle">❯</a>
                                    </div>
                                </div>
                                <div id="slide5" className="carousel-item relative w-full">
                                    <img src={car.image5} />
                                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                                        <a href="#slide3" className="btn btn-circle">❮</a>
                                        <a href="#slide1" className="btn btn-circle">❯</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-2">
                        <h2 className="text-2xl font-extrabold text-[#333]">
                            {car.title}
                        </h2>
                        <div className="flex flex-wrap gap-4 mt-6">
                            <p className="text-[#333] text-4xl font-bold">${car.price}</p>
                        </div>
                        <div className="flex flex-wrap gap-4 mt-10 justify-center sm:justify-start">
                            <button
                                onClick={() => setContactstate(prevState => !prevState)}
                                type="button"
                                className="min-w-[200px] sm:min-w-[400px] px-4 py-2.5 border border-[#333] bg-transparent hover:bg-gray-50 text-[#333] text-sm font-bold rounded"
                            >
                                Show Contact Details
                            </button>
                        </div>

                        {
                            contactstate ? (
                                <div>
                                </div>
                            ) : (
                                <div>
                                    <ul className="mt-6 space-y-6 text-[#333]">
                                        <li className="text-sm">
                                            Phone <span className="ml-4 float-right">{car.phone}</span>
                                        </li>
                                        <li className="text-sm">
                                            Email <span className="ml-4 float-right">{car.email}</span>
                                        </li>
                                        <li className="text-sm">
                                            Address <span className="ml-4 float-right">{car.address}</span>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }

                        <div className='mt-6 space-y-6 text-[#333]'>
                            {
                                car.desc ? (
                                    <div>
                                        <h3 className="text-lg font-bold text-[#333]">Car Description</h3>
                                        <p className="mt-6 text-[#333]">
                                            {car.desc}
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                    </div>
                                )
                            }
                        </div>


                    </div>
                </div>
                <div className="mt-16 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] p-6">
                    <h3 className="text-lg font-bold text-[#333]">Product information</h3>
                    <ul className="mt-6 space-y-6 text-[#333]">
                        <li className="text-sm">
                            CONDITION <span className="ml-4 float-right">{car.condition}</span>
                        </li>
                        <li className="text-sm">
                            MAKE <span className="ml-4 float-right">{car.make}</span>
                        </li>
                        <li className="text-sm">
                            MODEL <span className="ml-4 float-right">{car.model}</span>
                        </li>
                        <li className="text-sm">
                            YEAR <span className="ml-4 float-right">{car.year}</span>
                        </li>
                        <li className="text-sm">
                            ENGINE TYPE <span className="ml-4 float-right">{car.engine}</span>
                        </li>
                        <li className="text-sm">
                            HORSEPOWER <span className="ml-4 float-right">{car.hp} hp</span>
                        </li>
                        <li className="text-sm">
                            ACCELERATION <span className="ml-4 float-right">{car.accer}</span>
                        </li>
                        <li className="text-sm">
                            RANGE OR MILAGE <span className="ml-4 float-right">{car.range}</span>
                        </li>
                        <li className="text-sm">
                            DRIVE TYPE <span className="ml-4 float-right">{car.drive}</span>
                        </li>
                        <li className="text-sm">
                            TOP SPEED <span className="ml-4 float-right">{car.ts} kmh</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Details