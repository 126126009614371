import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from "react-router-dom";
import Home from "./Screens/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Cars from "./Screens/Cars";
import About from "./Screens/About";
import Details from "./Screens/Details";

function App() {
	return (
		<Router>
      <Navbar />
			<Routes>
				<Route exact path="/" element={<Home />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/about" element={<About />} />
        <Route path="/details/:id" element={<Details />} />
			</Routes>
      <Footer />
		</Router>
	);
}

export default App;
